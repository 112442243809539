<template lang="pug">
v-card-text
  v-text-field(
    v-model.trim="account.nick",
    :label="$t('account.form.nick')",
    prepend-icon="mdi-badge-account",
    :hint="$t('account.form.nickHint')",
    :rules="rules.nick"
  )

  v-radio-group(v-model="account.gender", row)
    v-radio(v-for="(g, i) in genders", :value="g.value", :key="i")
      template(v-slot:label) 
        v-icon(left) {{ g.icon }}
        | {{ g.text }}
  v-select(
    v-model="account.age",
    :label="$t('account.form.age')",
    prepend-icon="mdi-cake",
    :items="ageRanges"
  )
  v-text-field(
    v-model.trim="account.location",
    :label="$t('account.form.location')",
    prepend-icon="mdi-map-marker-star"
  )
  .text-caption {{ $t('account.details.explain') }}
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      account: {},
      genders: [
        {
          value: "f",
          text: this.$t("account.opts.gender.female"),
          icon: "mdi-gender-female",
        },
        {
          value: "m",
          text: this.$t("account.opts.gender.male"),
          icon: "mdi-gender-male",
        },
        {
          value: "~",
          text: this.$t("account.opts.gender.irrelevant"),
          icon: "mdi-help-circle-outline",
        },
      ],
      ageRanges: [
        { value: "~", text: this.$t("account.opts.age.irrelevant") },
        { value: "18", text: this.$t("account.opts.age.age18") },
        { value: "20", text: this.$t("account.opts.age.age20") },
        { value: "30", text: this.$t("account.opts.age.age30") },
        { value: "40", text: this.$t("account.opts.age.age40") },
      ],
      rules: {
        nick: [(v) => !!v || this.$t("account.rules.nickCannotBeEmpty")],
      },
    };
  },
  watch: {
    // account(val) {
    //   this.$emit("input", val);
    // },
  },
  mounted() {
    this.account = this.value;
  },
};
</script>
<template lang="pug">
v-container(fill-height)
  v-row(justify="center")
    v-col(md="6")
      v-form(v-model="accountFormValid", @submit.prevent="updateAccount")
        v-card(elevation="0")
          v-card-title 
            .text-h4 {{ $t('account.details.title') }}
          account-form(v-model="account")
          v-card-actions
            //- v-spacer
            v-btn.ml-auto.mr-auto(
              color="primary",
              type="submit",
              :disabled="!accountFormValid",
              block
            ) 
              v-icon(left) mdi-content-save
              | {{ $t('auth.btn.save') }}
            //- v-spacer
          v-card-actions.mt-4
            v-btn.ml-auto.mr-auto(text, @click="goLogout")
              v-icon(left) mdi-logout
              | {{ $t('auth.btn.logout') }}
</template>
<script>
import AccountForm from "@/components/AccountForm";
import { mapGetters } from "vuex";
export default {
  section: 'account',
  components: { AccountForm },
  data() {
    return {
      accountFormValid: false,
    };
  },
  computed: {
    ...mapGetters({
      account: "auth/account",
    }),
  },
  mounted() {},
  methods: {
    async updateAccount() {
      try {
        await this.$store.dispatch("auth/updateAccount");
        this.$snackbar({ content: this.$t("auth.msg.accountUpdated") });
      } catch (error) {
        this.$snackbar({
          content: this.$t("auth.errors.updateAccountFailed", { error }),
          color: "error",
        });
      }
    },
    goLogout() {
      this.$router.push({ name: "authLogout" });
    },
  },
};
</script>